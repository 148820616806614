import helper from '@/utils/helper'
import {DICT} from './dict'

const pageFilter = (value, pageNo, pageSize = 10) => {
  if (value === undefined || value === null || value === '') return
  return value + 1 + (pageNo - 1) * pageSize
}


const filters = {
  page: pageFilter,
  dictName: helper.getDictName,
  formatDate: helper.formatDate,
  formatTime: helper.formatTime,
  shortDateTime: helper.shortDateTime,
  maskMobile: helper.maskMobile,
  fullImgPath: helper.getFullImgPath,
  firstImage: helper.firstImage,
  fullAddress: helper.fullAddress,
  toNumber: helper.toNumber,

}

export default {
  install (Vue) {
    Object.keys(filters).forEach(key => {
      Vue.filter(key, filters[key])
    })
  }
}
