<template>
  <div class="container" :class="{mobile:isMobile}">
    <div class="content">
      <!--      <div class="top">-->
      <!--         <img alt="logo" class="logo" style="width:100px;" src="static/img/logo.jpg" />-->
      <!--        <div class="header">-->
      <!--          <span class="title">{{systemName}}</span>-->
      <!--        </div>-->
      <!--        <div class="desc"></div>-->
      <!--      </div>-->
      <div class="flexContent">
        <!--左侧信息-->
        <div class="leftContent">
          <div class="fsw36">诚信，是一切关系的基础</div>
          <div class="fsw20 mt16">诚信小程序后台数据管理</div>
          <div class="fsw20 mt16 mb20">
            明席卡乐旗下产品，诚信系统全套经营管理解决方案
          </div>
          <a href="http://www.mxklchina.com/" target="_blank"
            ><label class="detailLink mt16">了解更多</label></a
          >
          <!-- <image
            class="leftImg mt16"
            src="../../static/img/login_bg1.png"
            mode="aspectFill"
          ></image> -->
        </div>
        <!--右侧内容-->
        <div class="fsw36 right-title">诚信，是一切关系的基础</div>
        <div class="rightContent">
          <div class="formTitle">
            <div class="formTitleBorder"></div>
            <div v-if="pageType == 'login'">欢迎登录</div>
            <div v-if="pageType == 'reg'">欢迎注册</div>
            <div v-if="pageType == 'findPassword'">找回密码</div>
            <!-- <div v-if="pageType =='login'" class="formTitleRight" @click="pageType = 'reg'">免费注册</div> -->
            <div
              v-if="pageType == 'reg' || pageType == 'findPassword'"
              class="formTitleRight"
              @click="pageType = 'login'"
            >
              返回登录
            </div>
          </div>
          <!--登录-->
          <div v-if="pageType == 'login'">
            <component
              :is="componentName"
              @regist="handleRegist"
              class="main-content"
            ></component>
            <!--            <login @findPasswordFun="pageType = 'findPassword'"></login>-->
          </div>
          <!--注册-->
          <div v-else-if="pageType == 'reg'">
            <reg></reg>
          </div>
          <div v-else-if="pageType == 'findPassword'">
            <findPwd @toLoginPageFun="pageType = 'login'"></findPwd>
          </div>
          <!--建议使用的浏览器-->
          <div v-if="pageType != 'reg'" class="use-tip">
            建议使用
            <img src="/static/img/guge.png" mode="aspectFill" />
            Chrome、
            <img src="/static/img/huohu.png" mode="aspectFill" />
            火狐、
            <img src="/static/img/weiruan.png" mode="aspectFill" />
            Edge、
            <img src="/static/img/ie.png" mode="aspectFill" />
            360、
            <br />
            <img src="/static/img/sougou.png" mode="" />
            搜狗、
            <img src="/static/img/qqllq.png" mode="aspectFill" />
            QQ等浏览器
            <img src="/static/img/jishu.png" mode="aspectFill" />
            极速模式
          </div>
        </div>
      </div>
    </div>
    <global-footer :copyright="copyright" />
  </div>
</template>

<script>
import GlobalFooter from "../common/GlobalFooter";
import Login from "./Login";
import Regist from "./Regist";
import {mapState} from "vuex";

export default {
  name: "Common",
  components: { GlobalFooter, Login, Regist },
  data() {
    return {
      componentName: "Login",
      pageType: "login",
    };
  },
  computed: {
    ...mapState({
      isMobile: state => state.setting.isMobile
    }),
    systemName() {
      return this.$store.state.setting.systemName;
    },
    copyright() {
      return this.$store.state.setting.copyright;
    },
  },
  methods: {
    handleRegist(val) {
      this.componentName = val;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  width: 100%;
  min-width: 1500px;
  min-height: 720px;
  background-color: #fff;
  background-image: url(../../../static/img/bj.png);
  background-repeat: no-repeat;
  background-size: cover;
  //background-size: 100%;

  .content {
    padding: 32px 0;
    flex: 1;
    @media (min-width: 768px) {
      padding: 116px 0 10px;
    }
    .top {
      text-align: center;
      .header {
        height: 50px;
        line-height: 50px;
        a {
          text-decoration: none;
        }
        .logo {
          width: 40px;
          height: 19px;
          vertical-align: center;
          margin-right: 16px;
        }
        .title {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
          font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
            sans-serif;
          font-weight: 600;
          position: relative;
          top: 6px;
        }
      }
      .desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 12px;
        margin-bottom: 40px;
      }
    }
    .main-content {
      width: 368px;
      margin: 0 auto;
      @media screen and (max-width: 576px) {
        width: 95%;
      }
      @media screen and (max-width: 320px) {
        .captcha-button {
          font-size: 14px;
        }
      }
    }
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexContent {
  width: 1200px;
  min-height: 660px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .right-title {
    display: none;
  }
}

.leftContent {
  width: 500px;
  height: 500px;
  .detailLink {
    border: 1px solid #007aff;
    background-color: #fff;
    color: #007aff;
    border-radius: 20px;
    width: 120px;
    font-size: 16px;
    text-align: center;
    padding: 5px 20px;
    cursor: pointer;
  }
}

.leftImg {
  width: 450px;
  height: 320px;
  /* background-image: url(../../static/img/login_bg1.png);
    background-repeat: no-repeat;
    background-size: cover; */
}

.fsw36 {
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
}

.fsw28 {
  font-size: 28px;
  color: #ffffff;
}

.fsw20 {
  font-size: 20px;
  color: #ffffff;
}

.mt16 {
  margin-top: 16px;
}
.mb20 {
  margin-bottom: 20px;
}
.rightContent {
  background-color: #ffffff;
  width: 500px;
  height: 485px;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px;
  margin-top: -50px;
}

.formTitle {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.formTitleBorder {
  margin-right: 16px;
  width: 5px;
  height: 20px;
  border-radius: 2px;
  background-color: #007aff;
}

.formTitleRight {
  position: absolute;
  right: 16px;
  font-size: 16px;
  cursor: pointer;
}

.use-tip {
  width: 360px;
  margin: 0 54px;
  color: #999999;
  font-size: 15px;
  line-height: 26px;
}

.use-tip > img {
  width: 15px;
  height: 15px;
  margin: 0 0px 2px;
}


.mobile {
  min-width: 100vw;

  .content {
    max-width: 100vw;
    justify-content: flex-start;
  }

  .right-title {
    display: inherit;
  }

  .flexContent {
    width: 100vw;
    flex-direction: column;

    .leftContent {
      display: none;
    }

    .rightContent {
      width: 80vw;
      margin: auto;
      min-width: 400px;

      .use-tip {
        margin: 15px auto;
      }
    }
  }
}
</style>
