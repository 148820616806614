/* eslint-disable */
import moment from 'moment'
import {DICT} from '@/utils/dict'
import store from '../store'

const STATIC_IMG_DOMAIN = 'https://starcdn.mxklchina.com'

const shortDateTimeInner = function (val) {
    if (!val) return ''
    const nowMoment = moment()
    const dateStr = moment(val).format('YYYY-MM-DD HH:mm')
    const todayStr = nowMoment.format('YYYY-MM-DD')
    const yearStr = nowMoment.year()
    //判断是否今天
    let shortStr = dateStr.replace(todayStr, '今天')
    if (shortStr.indexOf(yearStr) < 0) {
        return shortStr
    }
    //判断是否昨天
    const yesterday = nowMoment.subtract(1, 'days').format('YYYY-MM-DD')
    shortStr = dateStr.replace(yesterday, '昨天')
    if (shortStr.indexOf(yearStr) < 0) {
        return shortStr
    }
    //判断是否前天
    const theDayBeforemYesterday = nowMoment.subtract(1, 'days').format('YYYY-MM-DD')
    shortStr = dateStr.replace(theDayBeforemYesterday, '前天')
    if (shortStr.indexOf(yearStr) < 0) {
        return shortStr
    }
    //判断是否明天
    const tommorrow = nowMoment.add(1, 'days').format('YYYY-MM-DD')
    shortStr = dateStr.replace(tommorrow, '明天')
    if (shortStr.indexOf(yearStr) < 0) {
        return shortStr
    }
    //判断是否后天
    const theDayAfterTommorrow = nowMoment.add(2, 'days').format('YYYY-MM-DD')
    shortStr = dateStr.replace(theDayAfterTommorrow, '后天')
    if (shortStr.indexOf(yearStr) < 0) {
        return shortStr
    }
    //替换掉年份
    return shortStr.replace(yearStr + '-', '')
}

//常用方法集合
export default {
    /*
     * obj 转 params字符串参数
     * 例子：{a:1,b:2} => a=1&b=2
     */
    objParseParam(obj) {
        let paramsStr = ''
        if (obj instanceof Array) return paramsStr
        if (!(obj instanceof Object)) return paramsStr
        for (let key in obj) {
            paramsStr += `${key}=${obj[key]}&`
        }
        return paramsStr.substring(0, paramsStr.length - 1)
    },
    /*
     * obj 转 路由地址带参数
     * 例子：{a:1,b:2} => /pages/index/index?a=1&b=2
     */
    objParseUrlAndParam(path, obj) {
        let url = path || '/'
        let paramsStr = ''
        if (obj instanceof Array) return url
        if (!(obj instanceof Object)) return url
        paramsStr = this.objParseParam(obj)
        paramsStr && (url += '?')
        url += paramsStr
        return url
    },
    /*
     * 获取url字符串参数
     */
    getRequestParameters(locationhref) {
        let href = locationhref || ''
        let theRequest = new Object()
        let str = href.split('?')[1]
        if (str != undefined) {
            let strs = str.split('&')
            for (let i = 0; i < strs.length; i++) {
                theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1]
            }
        }
        return theRequest
    },
    /**
     * 加密字符串
     */
    strEncode(str) {
        const key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        let l = key.length
        let a = key.split('')
        let s = '',
            b,
            b1,
            b2,
            b3
        for (let i = 0; i < str.length; i++) {
            b = str.charCodeAt(i)
            b1 = b % l
            b = (b - b1) / l
            b2 = b % l
            b = (b - b2) / l
            b3 = b % l
            s += a[b3] + a[b2] + a[b1]
        }
        return s
    },
    /**
     * 解密字符串
     */
    strDecode(str) {
        const key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        let l = key.length
        let b,
            b1,
            b2,
            b3,
            d = 0,
            s
        s = new Array(Math.floor(str.length / 3))
        b = s.length
        for (let i = 0; i < b; i++) {
            b1 = key.indexOf(str.charAt(d))
            d++
            b2 = key.indexOf(str.charAt(d))
            d++
            b3 = key.indexOf(str.charAt(d))
            d++
            s[i] = b1 * l * l + b2 * l + b3
        }
        b = eval('String.fromCharCode(' + s.join(',') + ')')
        return b
    },

    /**
     * H5复制
     */
    h5Copy(content) {
        let textarea = document.createElement('textarea')
        textarea.value = content
        textarea.readOnly = 'readOnly'
        document.body.appendChild(textarea)
        textarea.select() // 选择对象
        textarea.setSelectionRange(0, content.length) //核心
        let result = document.execCommand('Copy') // 执行浏览器复制命令
        textarea.remove()
        const msg = result ? '复制成功' : '复制失败'
        this.toast(msg)
    },

    // 去掉字符串中的空格
    trim(str) {
        if (!str) {
            return ''
        }
        return str.replace(/\s*/g, '')
    },

    // 判断两个对象是否相同
    isObjectValueEqual(x, y) {
        // 指向同一内存时
        if (x === y) {
            return true
        } else if (
            typeof x == 'object' &&
            x != null &&
            typeof y == 'object' && y != null
        ) {
            if (Object.keys(x).length != Object.keys(y).length) return false

            for (var prop in x) {
                if (y.hasOwnProperty(prop)) {
                    if (!this.isObjectValueEqual(x[prop], y[prop])) return false
                } else {
                    return false
                }
            }

            return true
        } else {
            return false
        }
    },

    //图片路径

    getFullImgPath(imgPath, defaultImg, retDefault) {
        imgPath = String(imgPath || '')
        imgPath = imgPath.replace(/(^\s*)|(\s*$)/g, '') //replace(/\s+|&nbsp;/ig, '');
        if (retDefault !== false && !imgPath) {
            return defaultImg// || mAssetsConfig.errorImage;
        }
        if (!!imgPath) {
            if (imgPath.indexOf('http://') == 0 || imgPath.indexOf('https://') == 0) {
                return imgPath
            } else if (imgPath.fullAddress > 300) {
                return 'data:image/jpg;base64,' + imgPath
            } else if (imgPath.indexOf('/static/') === 0) {
                return imgPath
            } else {
                return STATIC_IMG_DOMAIN + (imgPath.indexOf('/') == 0 ? '' : '/') + imgPath // 注册
            }
        }
        return ''
    },

    firstImage(imgPath) {
        if (!imgPath) {
            return '';
        }
        if (Array.isArray(imgPath)) {
            return imgPath.length > 0 ? imgPath[0] : '';
        }
        if (imgPath.indexOf("[") >= 0) {
            let arr = JSON.parse(imgPath);
            return arr.length > 0 ? arr[0] : '';
        }
        let arr2 = imgPath.split(/[,，、\r\n;]/g);
        return arr2.length > 0 ? arr2[0] : '';
    },
    formatDate(val, pattern = 'YYYY-MM-DD') {
        if (!val) return ''
        return moment(val).format(pattern)
    },
    formatTime(val, pattern = 'YYYY-MM-DD HH:mm:ss') {
        if (!val) return ''
        return moment(val).format(pattern)
    },

    maskMobile(mobile, mask = true) {
        if (!mobile) return ''
        if (!mask) return mobile
        return mobile.substr(0, 3) + '****' + mobile.substr(7, 10)
    },

    shortDateTime(val) {
        return shortDateTimeInner(val)
    },
    isValidName(val) {
        return /^[a-zA-Z_][0-9a-zA-Z_]{0,}$/.test(val)
    },

// 字典数据过滤
    getDictName(value, dictType, color) {
        if (value === undefined || value === null || value === '') {
            return ''
        }
        dictType = String(dictType)
        if (!dictType || !DICT[dictType]) {
            return value
        }
        let retValue = ''
        DICT[dictType].forEach(curData => {
            if (value.toString() === curData.value.toString()) {
                retValue = color ? curData.color : curData.label
            }
        })
        return retValue || value + ''
    },

    hasPermission(value) {
        let permissions = store.state.account.permissions
        let flag = true

        if (store.state.account.user.username === "superadmin") {
            return true;
        }
        if (permissions.indexOf(value) < 0) {
            flag = false
        }
        return flag
    },

    fullAddress(addressDTO, type, ignoreFields) {
        if (!addressDTO) return '';
        let retStr = '';
        if (type == 'from') {
            if (!ignoreFields || ignoreFields.indexOf('province') < 0) {
                retStr += addressDTO.fromProvince || ''
            }
            if (!ignoreFields || ignoreFields.indexOf('city') < 0) {
                retStr += addressDTO.fromCity || ''
            }
            if (!ignoreFields || ignoreFields.indexOf('area') < 0) {
                retStr += addressDTO.fromArea || ''
            }
            return retStr + (addressDTO.fromAddress || '')
        } else if (type == 'to') {
            if (!ignoreFields || ignoreFields.indexOf('province') < 0) {
                retStr += addressDTO.toProvince || ''
            }
            if (!ignoreFields || ignoreFields.indexOf('city') < 0) {
                retStr += addressDTO.toCity || ''
            }
            if (!ignoreFields || ignoreFields.indexOf('area') < 0) {
                retStr += addressDTO.toArea || ''
            }
            return retStr + (addressDTO.toAddress || '')
        } else {
            if (!ignoreFields || ignoreFields.indexOf('province') < 0) {
                retStr += addressDTO.province || ''
            }
            if (!ignoreFields || ignoreFields.indexOf('city') < 0) {
                retStr += addressDTO.city || ''
            }
            if (!ignoreFields || ignoreFields.indexOf('area') < 0) {
                retStr += addressDTO.area || ''
            }
            return retStr + (addressDTO.address || '')
        }
    },
    toNumber(value, digitCount = 2) {
        // return (Math.floor((value || 0) * 100.00) / 100.00).toFixed(2);
        return Number(Number(value || 0).toFixed(digitCount)) //最多两个小数
    },

    daysDiff(beginDate, endData) {
        return moment(endData).diff(moment(beginDate), 'days') + 1;
    }
}
