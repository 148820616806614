<template>
  <div class="login">
    <a-form @submit.prevent="doLogin" :form="form">
      <a-tabs
        size="large"
        :tabBarStyle="{ textAlign: 'center', marginTop: '0px' }"
        style="padding: 0 2px"
        :activeKey="activeKey"
        @change="handleTabsChange"
      >
        <a-tab-pane tab="密码登录" key="1" style="padding: 10px 0">
          <a-alert
            type="error"
            :closable="true"
            v-show="error"
            :message="error"
            showIcon
            style="margin-bottom: 24px"
          ></a-alert>
          <a-form-item>
            <a-input
              size="large"
              placeholder="请输入用户名称"
              v-decorator.trim="[
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入用户名称',
                      whitespace: true,
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="user"></a-icon>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              size="large"
              type="password"
              placeholder="请输入密码"
              v-decorator.trim="[
                'password',
                {
                  rules: [
                    { required: true, message: '请输入密码', whitespace: true },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="lock"></a-icon>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-row :gutter="8" style="margin: 0 -4px">
              <a-col :span="16">
                <a-input
                  size="large"
                  placeholder="请输入验证码"
                  :max-length="4"
                  v-decorator.trim="[
                    'verifiCode',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入验证码',
                          whitespace: true,
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="safety-certificate"></a-icon>
                </a-input>
              </a-col>
              <a-col :span="8" style="padding-left: 4px">
                <img
                  @click="getVerifiCode"
                  style="width: 100%; height: 40px"
                  :src="verifiImg"
                  alt=""
                />
              </a-col>
            </a-row>
          </a-form-item>
        </a-tab-pane>
        <a-tab-pane tab="验证码登录" key="2" style="padding: 10px 0">
          <a-form-item>
            <a-input
              size="large"
              placeholder="请输入手机号"
              v-decorator="[
                'mobile',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入手机号',
                      whitespace: true,
                    },
                    {
                      pattern: /^1[3456789]\d{9}$/,
                      message: '请输入正确的手机号码',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="mobile"></a-icon>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-row :gutter="8" style="margin: 0 -4px">
              <a-col :span="16">
                <a-input
                  size="large"
                  placeholder="请输入验证码"
                  v-decorator="[
                    'verifyCode',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入验证码',
                          whitespace: true,
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="mail"></a-icon>
                </a-input>
              </a-col>
              <a-col :span="8" style="padding-left: 4px">
                <a-button
                  style="width: 100%"
                  class="captcha-button"
                  size="large"
                  @click="getCaptcha"
                  v-if="disabled"
                  >获取验证码
                </a-button>
                <a-button
                  v-if="!disabled"
                  style="width: 100%"
                  class="captcha-button"
                  size="large"
                  disabled
                  >{{ vCodetimer }}
                </a-button>
              </a-col>
            </a-row>
          </a-form-item>
        </a-tab-pane>
      </a-tabs>
      <a-form-item>
        <a-button
          :loading="loading"
          style="width: 100%; margin-top: 4px"
          size="large"
          htmlType="submit"
          type="primary"
        >
          登录
        </a-button>
      </a-form-item>
      <!--      <div>-->
      <!--        <a style="float: right" @click="regist">注册账户</a>-->
      <!--      </div>-->
    </a-form>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      error: "",
      activeKey: "1",
      verifiImg: "",
      disabled: true,
      vCodetimer: 60,
    };
  },
  computed: {
    systemName() {
      return this.$store.state.setting.systemName;
    },
    copyright() {
      return this.$store.state.setting.copyright;
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "login_form" });
  },
  created() {
    this.$db.clear();
    this.$router.options.routes = [];
    this.getVerifiCode();
  },
  methods: {
    getVerifiCode() {
      this.$getBlob("getVerifiCode").then((res) => {
        this.verifiImg = res;
      });
    },
    doLogin() {
      if (this.activeKey === "1") {
        // 用户名密码登录
        this.form.validateFields(
          ["name", "password", "verifiCode"],
          (errors, values) => {
            if (!errors) {
              this.loading = true;
              let loginData = {
                username: this.form.getFieldValue("name"),
                password: this.form.getFieldValue("password"),
                verifiCode: this.form.getFieldValue("verifiCode"),
              };
              this.$post("login", loginData)
                .then((r) => {
                  let data = r.data;
                  this.saveLoginData(data);
                  this.setUpsetTimeout();
                  this.$router.push("/");
                })
                .catch(() => {
                  this.form.setFieldsValue({verifiCode:''})
                  this.setUpsetTimeout("1");
                  // setTimeout(() => {
                  //   this.loading = false;
                  //   this.getVerifiCode();
                  // }, 500);
                });
            }
          }
        );
      }
      if (this.activeKey === "2") {
        // 手机验证码登录
        // this.$message.warning("暂未开发");
        this.form.validateFields(["mobile", "verifyCode"], (errors, values) => {
          if (!errors) {
            this.loading = true;
            let loginData = {
              mobile: this.form.getFieldValue("mobile"),
              verifyCode: this.form.getFieldValue("verifyCode"),
            };
            this.$post("loginByMobile", loginData)
              .then((res) => {
                let data = res.data;
                this.saveLoginData(data);
                this.setUpsetTimeout();
                this.$router.push("/");
              })
              .catch(() => {
                this.setUpsetTimeout();
              });
          }
        });
      }
    },
    setUpsetTimeout(num) {
      setTimeout(() => {
        this.loading = false;
        if (num == 1) {
          this.getVerifiCode();
        }
      }, 500);
    },
    regist() {
      this.$emit("regist", "Regist");
    },
    getCaptcha() {
      // this.$message.warning("暂未开发");
      this.form.validateFields(["mobile"], (errors, values) => {
        if (!errors) {
          this.$get("getSmsCode", {
            mobile: this.form.getFieldValue("mobile"),
            type: "adminLogin",
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success("验证码已发送，请检查手机短信");
              this.disabled = false;
              const authTimer = setInterval(() => {
                this.vCodetimer--;
                if (this.vCodetimer <= 0) {
                  this.disabled = true;
                  this.vCodetimer = 60;
                  clearInterval(authTimer);
                }
              }, 1000);
            } else {
              this.disabled = true;
            }
          });
        }
      });
    },
    getVerification() {},
    handleTabsChange(val) {
      this.activeKey = val;
    },
    ...mapMutations({
      setToken: "account/setToken",
      setExpireTime: "account/setExpireTime",
      setPermissions: "account/setPermissions",
      setRoles: "account/setRoles",
      setUser: "account/setUser",
      setSeatCartCount: "account/setSeatCartCount",
      setTheme: "setting/setTheme",
      setLayout: "setting/setLayout",
      setMultipage: "setting/setMultipage",
      fixSiderbar: "setting/fixSiderbar",
      fixHeader: "setting/fixHeader",
      setColor: "setting/setColor",
    }),
    saveLoginData(data) {
      this.setToken(data.token);
      this.setExpireTime(data.exipreTime);
      this.setUser(data.user);
      this.setPermissions(data.permissions);
      this.setRoles(data.roles);
      this.setTheme((data.config && data.config.theme) || "light");
      this.setColor((data.config && data.config.color) || "rgb(24, 144, 255)");
      this.setLayout((data.config && data.config.layout) || "side");
      //删除自配置部分
      this.setMultipage(data.config && data.config.multiPage === "1");
      this.fixSiderbar(data.config && data.config.fixSiderbar === "1");
      this.fixHeader(data.config && data.config.fixHeader === "1");

      // this.$get("user/seatcart/count").then((res) => {
      //   this.setSeatCartCount(+res.data);
      // });

      // this.$get("sysConfig/supportSiteType").then((res) => {
      //   this.$db.save("SUPPORTED_SITE_TYPE", res.data || []);
      // });
      // this.$get("seattype/getAll").then((res) => {
      //   this.$db.save("SUPPORTED_SEAT_TYPE", res.data || []);
      // });
    },
  },
};
</script>

<style lang="less">
.login {
  .icon {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.2);
    margin-left: 16px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .ant-tabs-nav-wrap {
    //display: none;
  }
}
</style>
