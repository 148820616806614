//可拖曳对话框

export const dragModal = {
    install(Vue) {
        Vue.directive('drag-modal', (el, bindings, vnode) => {
            Vue.nextTick(() => {
                let {visible, destroyOnClose} = vnode.componentInstance
                // 防止未定义 destroyOnClose 关闭弹窗时dom未被销毁，指令被重复调用
                if (!visible) return
                let modal = el.getElementsByClassName('ant-modal')[0]
                let header = el.getElementsByClassName('ant-modal-header')[0]
                if(!modal||!header){
                    return
                }
                let topPropName = "top"//top属性名称，如果是element-UI，则使用功能marginTop
                if (!modal) {
                    modal = el.getElementsByClassName('el-dialog')[0]
                    header = el.getElementsByClassName('el-dialog__header')[0]
                    topPropName = "marginTop"
                }

                let left = 0
                let top = 0
                header.style.cursor = "move"

                // 未定义 destroyOnClose 时，dom未被销毁，关闭弹窗再次打开，弹窗会停留在上一次拖动的位置
                if (!destroyOnClose) {
                    left = modal.left || 0
                    top = modal[topPropName] || 0
                }
                // top 初始值为 offsetTop
                top = top || modal.offsetTop

                header.onmousedown = e => {
                    let startX = e.clientX;
                    let startY = e.clientY;
                    header.left = header.offsetLeft;
                    header.top = header.offsetTop;
                    el.onmousemove = event => {
                        let endX = event.clientX;
                        let endY = event.clientY;
                        modal.left = header.left + (endX - startX) + left;
                        modal[topPropName] = header.top + (endY - startY) + top;
                        modal.style.left = modal.left + 'px'
                        modal.style[topPropName] = modal[topPropName] + 'px'
                    }
                    el.onmouseup = event => {
                        left = modal.left
                        top = modal[topPropName]
                        el.onmousemove = null;
                        el.onmouseup = null;
                        header.releaseCapture && header.releaseCapture();
                    }
                    header.setCapture && header.setCapture();
                }
            })
        })
    }
}
